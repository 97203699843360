
class UrlPathname extends HTMLElement {
  constructor () {
    super()
  }
  connectedCallback () {
    this.innerText = location.pathname
  }
}

customElements.define('url-pathname', UrlPathname)

declare global {
  interface HTMLElementTagNameMap {
    'url-pathname': UrlPathname
  }
}
